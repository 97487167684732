import { KODEX_COMPANY_ID } from "@/constants";
import { throwErrorResponse } from "@/helpers";
import AuthClient from "@/http/authClient";

export default function adminLoader() {
  const authToken = AuthClient.getToken();
  if (!authToken) {
    return throwErrorResponse({ status: 404, statusText: "Not found" });
  }
  const { company_id } = AuthClient.getDecodedToken(authToken);

  if (company_id !== KODEX_COMPANY_ID) {
    return throwErrorResponse({ status: 404, statusText: "Not found" });
  }
  return null;
}
