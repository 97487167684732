import { atom, getDefaultStore } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";
import type { WorkspaceTypeLite } from "./http/api/workspaces/types";
import type { Location } from "react-router-dom";
import { getInitialWorkspace } from "./helpers";

const defaultStore = getDefaultStore();

const sideMenuStorage = createJSONStorage<boolean>(() => sessionStorage);
export const sideMenuAtom = atomWithStorage<boolean>(
  "showSideMenu",
  true,
  sideMenuStorage,
);

export const historyAtom = atom<Location[]>([]);

export const userAtom = atom<Partial<UserAtomType>>({});

export const companyIdAtom = atom(
  (get) => get(userAtom).company_id ?? "",
  (get, set, companyId: string) => {
    const user = get(userAtom);
    set(userAtom, { ...user, company_id: companyId });
  },
);

export const setCompanyId = (companyId: string) => {
  defaultStore.set(companyIdAtom, companyId);
};

export const isCompanyAdminAtom = atom(
  (get) => get(userAtom).isAdmin ?? false,
  (get, set, isAdmin: boolean) => {
    const user = get(userAtom);
    set(userAtom, { ...user, isAdmin });
  },
);

export const setIsCompanyAdmin = (isAdmin: boolean) => {
  defaultStore.set(isCompanyAdminAtom, isAdmin);
};

export const userWorkspacesAtom = atom<WorkspaceTypeLite[]>([]);
export const setUserWorkspaces = (userWorkspaces: WorkspaceTypeLite[]) => {
  defaultStore.set(userWorkspacesAtom, userWorkspaces);
};

const currentWorkspaceIdStorage = createJSONStorage<string | null>(
  () => sessionStorage,
);
export const currentWorkspaceIdAtom = atomWithStorage<string | null>(
  "currentWorkspaceId",
  null,
  currentWorkspaceIdStorage,
);

export const getCurrentWorkspace = atom((get) => {
  const currentWorkspaces = get(userWorkspacesAtom);
  const currentWorkspaceId = get(currentWorkspaceIdAtom);
  return currentWorkspaceId
    ? currentWorkspaces.find((workspace) => workspace.id === currentWorkspaceId)
    : getInitialWorkspace(get(userWorkspacesAtom));
});

export interface UserAtomType {
  id: string;
  email: string;
  company_id: string;
  isAdmin: boolean;
}

// An atom to show disclaimer notifications
// Currently used in editor after creating a market newsflash report
export const showDisclaimerNotificationAtom = atom(false);
